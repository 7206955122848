import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, Outlet } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { Loader2 } from 'lucide-react';

// Import all context providers
import { GHGProvider } from './context/GHGContext';
import { UnifiedGHGProvider } from "./context/UnifiedGHGContext";
import Scope3InitialAssessmentSettingsPage from './pages/Settings/Scope3InitialAssessmentSettingsPage';

// Direct import for AdminSignIn to avoid lazy loading issues
import AdminSignIn from "./components/Auth/AdminSignIn";

// Auth Components
const SignUp = lazy(() => import("./components/Auth/SignUp"));
const SignIn = lazy(() => import("./components/Auth/SignIn"));
const AuthenticatedRoute = lazy(() => import("./components/Protected/AuthenticatedRoute"));
const AdminRoute = lazy(() => import("./components/Protected/AdminRoute"));

// Layouts
const Layout = lazy(() => import("./layout/Layout"));
const AdminLayout = lazy(() => import("./layout/AdminLayout"));

// Lazy load all pages
const RecorderPage = lazy(() => import("./pages/RecorderPage"));
// const ResultsPage = lazy(() => import("./pages/ResultsPage"));
const SettingsPage = lazy(() => import("./pages/SettingsPage"));
const CompanyInfoPage = lazy(() => import("./pages/CompanyInfoPage"));
const OrganizationalBoundaryPage = lazy(() => import("./pages/OrganizationalBoundaryPage"));
const OperationalBoundaryPage = lazy(() => import("./pages/Settings/OperationalBoundaryPage"));
const AnalyticsPage = lazy(() => import("./pages/AnalyticsPage"));
const BaseYearSettingsPage = lazy(() => import("./pages/Settings/BaseYearSettingsPage"));
const EnhancedDashboard = lazy(() => import("./pages/EnhancedDashboard/EnhancedDashboard"));
const AdminPanel = lazy(() => import("./pages/Admin/AdminPanel"));

// Scope 1 Pages
const StationaryCombustionPage = lazy(() => import("./pages/DashboardPages/Scope1/Scope1StationaryCombustion_DashboardPage"));
const MobileCombustionPage = lazy(() => import('./pages/DashboardPages/Scope1/Scope1MobileCombustion_DashboardPage'));
const FugitiveEmissionsPage = lazy(() => import('./pages/DashboardPages/Scope1/Scope1FugitiveEmissions_DashboardPage'));
const ProcessEmissionsPage = lazy(() => import('./pages/DashboardPages/Scope1/Scope1ProcessEmissions_DashboardPage'));
const Scope1Page = lazy(() => import('./pages/DashboardPages/Scope1_DashboardPage'));

// Scope 2 Pages
const PurchasedElectricityPage = lazy(() => import('./pages/DashboardPages/Scope2/Scope2PurchasedElectricity_DashboardPage'));
const PurchasedHeatingPage = lazy(() => import('./pages/DashboardPages/Scope2/Scope2PurchasedHeating_DashboardPage'));
const Scope2Page = lazy(() => import('./pages/DashboardPages/Scope2_DashboardPage'));

// Scope 3 Pages
const Scope3Page = lazy(() => import('./pages/DashboardPages/Scope3_DashboardPage'));

// Emissions Breakdown Page
const EmissionsBreakdownPage = lazy(() => import('./pages/EmissionsBreakdown/EmissionsBreakdownPage'));

// Report Page
const ReportPage = lazy(() => import('./pages/Reports/ReportPage'));


// Loading Overlay Component
const LoadingOverlay = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(true), 300);
    return () => clearTimeout(timer);
  }, []);

  if (!showLoader) return null;

  return (
    <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
      <div className="flex flex-col items-center gap-2">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
        <p className="text-gray-600">Loading...</p>
      </div>
    </div>
  );
};

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, retryCount: 0 };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Error caught by boundary:', error, errorInfo);
    }
  }

  handleRetry = () => {
    this.setState(prevState => ({
      hasError: false,
      retryCount: prevState.retryCount + 1
    }));
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
          <div className="p-8 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h2>
            <p className="text-gray-600 mb-4">
              {this.state.retryCount >= 3 
                ? "Please try refreshing the page or contact support."
                : "An error occurred while loading the page."}
            </p>
            {this.state.retryCount < 3 && (
              <button 
                onClick={this.handleRetry}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                Retry Loading
              </button>
            )}
            {this.state.retryCount >= 3 && (
              <button 
                onClick={() => window.location.reload()} 
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                Refresh Page
              </button>
            )}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// Protected Layout Component
const ProtectedLayout = ({ isSidebarCollapsed, onToggleSidebar }) => {
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    const handlePageTransition = async () => {
      setIsPageLoading(true);
      await new Promise(resolve => setTimeout(resolve, 100));
      if (mounted) {
        setIsPageLoading(false);
      }
    };

    handlePageTransition();

    return () => {
      mounted = false;
    };
  }, [location.pathname]);

  return (
    <Layout
      isSidebarCollapsed={isSidebarCollapsed}
      onToggleSidebar={onToggleSidebar}
    >
      {isPageLoading ? <LoadingOverlay /> : <Outlet />}
    </Layout>
  );
};

// Providers Wrapper Component
const ProvidersWrapper = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [initError, setInitError] = useState(null);

  useEffect(() => {
    const initializeProviders = async () => {
      try {
        // Validate environment variables
        if (!process.env.REACT_APP_API_URL) {
          throw new Error('Missing required environment variable: REACT_APP_API_URL');
        }

        await new Promise(resolve => setTimeout(resolve, 100));
        setIsInitialized(true);
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error('Provider initialization error:', error);
        }
        setInitError(error);
      }
    };

    initializeProviders();
  }, []);

  if (initError) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Initialization Error</h2>
          <p className="text-gray-600 mb-4">
            {initError.message || 'Failed to initialize application. Please check configuration.'}
          </p>
          <button 
            onClick={() => window.location.reload()} 
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (!isInitialized) {
    return <LoadingOverlay />;
  }

  return (
    <ErrorBoundary>
      <UnifiedGHGProvider>
        <GHGProvider>
          <Suspense fallback={<LoadingOverlay />}>
            {children}
          </Suspense>
        </GHGProvider>
      </UnifiedGHGProvider>
    </ErrorBoundary>
  );
};

function App() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <Router>
      <ProvidersWrapper>
        <div className={`app-container ${isSidebarCollapsed ? "collapsed" : ""}`}>
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 3000,
              style: {
                background: "#333",
                color: "#fff",
              },
            }}
          />

          <Routes>
            {/* Admin Routes - Must be first and at root level */}
            <Route path="/admin/signin" element={
              <div className="min-h-screen bg-gray-50">
                <AdminSignIn />
              </div>
            } />
            <Route path="/admin" element={
              <Suspense fallback={<LoadingOverlay />}>
                <AdminRoute>
                  <AdminLayout>
                    <AdminPanel />
                  </AdminLayout>
                </AdminRoute>
              </Suspense>
            } />

            {/* Public Routes */}
            <Route path="/signup" element={
              <Suspense fallback={<LoadingOverlay />}>
                <SignUp />
              </Suspense>
            } />
            <Route path="/signin" element={
              <Suspense fallback={<LoadingOverlay />}>
                <SignIn />
              </Suspense>
            } />

            {/* Protected Routes - Must be last */}
            <Route path="/" element={
              <Suspense fallback={<LoadingOverlay />}>
                <AuthenticatedRoute>
                  <ProtectedLayout
                    isSidebarCollapsed={isSidebarCollapsed}
                    onToggleSidebar={toggleSidebar}
                  />
                </AuthenticatedRoute>
              </Suspense>
            }>
              <Route index element={<Navigate to="/enhanced-dashboard" replace />} />
              <Route path="dashboard" element={<Navigate to="/enhanced-dashboard" replace />} />
              <Route path="enhanced-dashboard" element={<EnhancedDashboard />} />
              <Route path="analytics" element={<AnalyticsPage />} />
              <Route path="recorder" element={<RecorderPage />} />
              {/* <Route path="results" element={<ResultsPage />} /> */}

              {/* Settings Routes */}
              <Route path="settings">
                <Route index element={<SettingsPage />} />
                <Route path="company-info" element={<CompanyInfoPage />} />
                <Route path="operational-boundary" element={<OperationalBoundaryPage />} />
                <Route path="organizational-boundary" element={<OrganizationalBoundaryPage />} />
                <Route path="base-year" element={<BaseYearSettingsPage />} />
                <Route path="scope3-assessment" element={<Scope3InitialAssessmentSettingsPage />} />
              </Route>

              {/* Scope Routes */}
              <Route path="scope1">
                <Route index element={<Scope1Page />} />
                <Route path="stationary-combustion" element={<StationaryCombustionPage />} />
                <Route path="mobile-combustion" element={<MobileCombustionPage />} />
                <Route path="fugitive-emissions" element={<FugitiveEmissionsPage />} />
                <Route path="process-emissions" element={<ProcessEmissionsPage />} />
              </Route>

              <Route path="scope2">
                <Route index element={<Scope2Page />} />
                <Route path="purchased-electricity" element={<PurchasedElectricityPage />} />
                <Route path="purchased-heating" element={<PurchasedHeatingPage />} />
              </Route>

              <Route path="scope3">
                <Route index element={<Scope3Page />} />
              </Route>

              <Route path="emissions/breakdown" element={<EmissionsBreakdownPage />} />
              <Route path="reports" element={<ReportPage />} />

              {/* Fallback Route */}
              <Route path="*" element={<Navigate to="/enhanced-dashboard" replace />} />
            </Route>
          </Routes>
        </div>
      </ProvidersWrapper>
    </Router>
  );
}

export default App;

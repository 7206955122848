import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';

import {
  API_BASE_URL,
  CACHE_DURATION,
  MIN_REFRESH_INTERVAL,
  MAX_RETRIES,
  RETRY_DELAY,
  ENDPOINTS,
  initialEmissionsData,
  initialBaseYearData,
  getInitialDateRange
} from './UnifiedGHGContext/UnifiedGHGContextPart2';

import {
  safeParseNumber,
  calculateTotals,
  processTimeSeriesData
} from './UnifiedGHGContext/UnifiedGHGContextPart3';

const UnifiedGHGContext = createContext(null);

export const useUnifiedGHG = () => {
  const context = useContext(UnifiedGHGContext);
  if (!context) {
    throw new Error('useUnifiedGHG must be used within a UnifiedGHGProvider');
  }
  return context;
};

export const UnifiedGHGProvider = ({ children }) => {
  const mountedRef = useRef(false);
  const fetchInProgress = useRef(false);
  const lastFetchRef = useRef(null);
  const initializationAttempted = useRef(false);
  const authCheckInterval = useRef(null);
  const dataCache = useRef({});
  const baseYearFetchTimeout = useRef(null);
  
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emissionsData, setEmissionsData] = useState(initialEmissionsData);
  const [baseYearData, setBaseYearData] = useState(initialBaseYearData);
  const [dateRange, setDateRange] = useState(getInitialDateRange);
  const [earliestDate, setEarliestDate] = useState(null);
  const [latestDate, setLatestDate] = useState(null);
  const [reportingStartMonth, setReportingStartMonth] = useState(() => {
    // Try to get from localStorage if available
    const savedMonth = localStorage.getItem('ghg_reporting_start_month');
    return savedMonth ? parseInt(savedMonth) : 1; // Default to January (1)
  });

  // Clear the entire cache - used when reporting period changes
  const clearCache = useCallback(() => {
    dataCache.current = {};
  }, []);

  const debouncedSetLoading = useCallback(
    debounce((value) => {
      if (mountedRef.current) {
        setLoading(value);
      }
    }, 300),
    []
  );

  const shouldRefreshData = useCallback(() => {
    const now = Date.now();
    if (fetchInProgress.current) return false;
    if (!lastFetchRef.current) return true;
    if (now - lastFetchRef.current < MIN_REFRESH_INTERVAL) return false;
    return now - lastFetchRef.current > CACHE_DURATION;
  }, []);

  const makeRequest = useCallback(async (endpoint, options = {}) => {
    let attempt = 0;
    
    while (attempt < MAX_RETRIES) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication required');
        }

        const skipCache = endpoint === '/api/base-year' || options.skipCache;
        
        // Include params in cache key if they exist
        const paramString = options.params ? JSON.stringify(options.params) : '';
        const cacheKey = `${endpoint}${options.body ? JSON.stringify(options.body) : ''}${paramString}`;
        
        if (!skipCache) {
          const cachedData = dataCache.current[cacheKey];
          if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
            return cachedData.data;
          }
        }

        // Ensure endpoint is properly formatted
        let apiEndpoint = endpoint;
        if (!endpoint.startsWith('/api/')) {
          apiEndpoint = `/api/${endpoint.startsWith('/') ? endpoint.slice(1) : endpoint}`;
        }

        // Add query parameters if provided
        if (options.params) {
          const queryParams = new URLSearchParams();
          Object.entries(options.params).forEach(([key, value]) => {
            queryParams.append(key, value.toString());
          });
          
          const queryString = queryParams.toString();
          apiEndpoint = `${apiEndpoint}${queryString ? `?${queryString}` : ''}`;
        }
        
        const url = `${API_BASE_URL}${apiEndpoint}`;

        const response = await fetch(url, {
          ...options,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...options.headers
          }
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('Authentication required');
          }
          if (response.status === 404) {
            if (options.suppressNotFound) {
              return null;
            }
            return [];
          }
          throw new Error(`Request failed: ${response.statusText}`);
        }

        const data = await response.json();
        
        if (!skipCache) {
          dataCache.current[cacheKey] = {
            data,
            timestamp: Date.now()
          };
        }
        
        return data;

      } catch (error) {
        attempt++;
        if (attempt === MAX_RETRIES || error.message === 'Authentication required') {
          throw error;
        }
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * attempt));
      }
    }
  }, []);

  const findDateBoundaries = useCallback((data) => {
    let earliest = null;
    let latest = null;

    const processEntry = (entry) => {
      if (!entry?.startDate || !entry?.co2e_emissions) return;
      
      // Only process entries that have actual emissions data
      const emissions = parseFloat(entry.co2e_emissions);
      if (isNaN(emissions) || emissions === 0) return;

      // Get both start and end dates
      const startDate = new Date(entry.startDate);
      const endDate = entry.endDate ? new Date(entry.endDate) : startDate;
      
      // Update earliest date based on start date
      if (!earliest || startDate < earliest) earliest = startDate;
      
      // Update latest date based on end date, but only if it's not in the future
      const now = new Date();
      if (!latest || (endDate > latest && endDate <= now)) latest = endDate;
    };

    Object.values(data).forEach(scope => {
      Object.values(scope).forEach(category => {
        if (Array.isArray(category.data)) {
          category.data.forEach(processEntry);
        }
      });
    });

    return { earliest, latest };
  }, []);

  const batchFetchEmissionsData = useCallback(async () => {
    if (!mountedRef.current || fetchInProgress.current) return;

    try {
      fetchInProgress.current = true;

      // Filter out non-emissions endpoints first
      const validEndpoints = ['scope1', 'scope2', 'scope3'];
      const requests = Object.entries(ENDPOINTS)
        .filter(([scope]) => validEndpoints.includes(scope))
        .flatMap(([scope, endpoints]) =>
          Object.entries(endpoints).map(([key, endpoint]) => ({
            scope,
            key,
            endpoint: endpoint.toString(),
          }))
        );

      const results = await Promise.all(
        requests.map(async ({ scope, key, endpoint }) => {
          try {
            const data = await makeRequest(endpoint, { suppressNotFound: true });
            return { scope, key, data, error: null };
          } catch (error) {
            return { scope, key, data: [], error };
          }
        })
      );

      if (!mountedRef.current) return;

      const newData = { ...initialEmissionsData };
      let hasValidData = false;

      results.forEach(({ scope, key, data, error }) => {
        if (!newData[scope]) newData[scope] = {};
        const processedData = Array.isArray(data) ? data : data?.entries || [];
        if (processedData.length > 0) hasValidData = true;
        
        newData[scope][key] = {
          data: processedData,
          lastUpdated: new Date(),
          loading: false,
          error: error?.message
        };
      });

      if (hasValidData) {
        const { earliest, latest } = findDateBoundaries(newData);
        if (earliest) setEarliestDate(earliest);
        if (latest) setLatestDate(latest);
        setEmissionsData(newData);
      }

    } finally {
      if (mountedRef.current) {
        fetchInProgress.current = false;
      }
    }
  }, [makeRequest, findDateBoundaries]);

  const calculateBaseYearTotals = useCallback((emissionsData, year, month = reportingStartMonth) => {
    if (!emissionsData || !year) return null;

    // For fiscal years (non-January start), the start date should be in the previous year
    // For calendar years (January start), use the provided year
    const startYear = month === 1 ? year : year - 1;
    
    // Calculate reporting period date range
    const startMonth = month - 1; // 0-based month
    const startDate = new Date(startYear, startMonth, 1);
    
    // End date is 12 months later, last day of the month
    const endMonth = (startMonth + 11) % 12;
    // For calendar year (Jan start), end year is same as start year
    // For fiscal year, end year is the provided year (which is the year it ends in)
    const endYear = month === 1 ? year : year;
    const lastDayOfMonth = new Date(endYear, endMonth + 1, 0).getDate();
    const endDate = new Date(endYear, endMonth, lastDayOfMonth, 23, 59, 59);

    // Helper function to calculate total for a category within the reporting period
    const calculateTotal = (data, method = 'Location-based') => {
      if (!Array.isArray(data)) return 0;
      
      const periodData = data.filter(entry => {
        const entryDate = new Date(entry.startDate);
        const isInRange = entryDate >= startDate && entryDate <= endDate;
        return isInRange;
      });

      // Return 0 if no data for the period
      if (periodData.length === 0) {
        return 0;
      }

      const total = periodData.reduce((sum, entry) => {
        let emissions = 0;
        if (method === 'Market-based' && entry.marketBasedData?.marketBasedEmissions) {
          emissions = parseFloat(entry.marketBasedData.marketBasedEmissions);
        } else {
          emissions = parseFloat(entry.co2e_emissions || 0);
        }
        return sum + (isNaN(emissions) ? 0 : emissions);
      }, 0);

      return total;
    };

    // Calculate Scope 1 total
    const scope1Total = Number((
      calculateTotal(emissionsData.scope1?.stationaryCombustion?.data) +
      calculateTotal(emissionsData.scope1?.mobileCombustionLandFB?.data) +
      calculateTotal(emissionsData.scope1?.mobileCombustionLandDB?.data) +
      calculateTotal(emissionsData.scope1?.fugitiveEmissions?.data) +
      calculateTotal(emissionsData.scope1?.processEmissions?.data)
    ).toFixed(2));

    // Calculate Scope 2 totals (both location-based and market-based)
    const scope2LocationTotal = Number((
      calculateTotal(emissionsData.scope2?.purchasedElectricityLB?.data, 'Location-based') +
      calculateTotal(emissionsData.scope2?.purchasedHeating?.data)
    ).toFixed(2));

    const scope2MarketTotal = Number((
      calculateTotal(emissionsData.scope2?.purchasedElectricityLB?.data, 'Market-based') +
      calculateTotal(emissionsData.scope2?.purchasedHeating?.data)
    ).toFixed(2));

    // Calculate Scope 3 total
    const scope3Total = Number((
      calculateTotal(emissionsData.scope3?.purchasedGSSS?.data) +
      calculateTotal(emissionsData.scope3?.purchasedGSSB?.data) +
      calculateTotal(emissionsData.scope3?.capitalGoodsSS?.data) +
      calculateTotal(emissionsData.scope3?.fuelandEnergy?.data) +    
      calculateTotal(emissionsData.scope3?.upstreamTransportationLandDB?.data) +
      calculateTotal(emissionsData.scope3?.wasteGenerated?.data) +
      calculateTotal(emissionsData.scope3?.businessTravelLandDB?.data) +
      calculateTotal(emissionsData.scope3?.employeeCommutingLandDB?.data) +
      calculateTotal(emissionsData.scope3?.upstreamLeasedAssets?.data)
    ).toFixed(2));

    // Calculate total emissions for both methods
    const totalEmissionsLocation = Number((scope1Total + scope2LocationTotal + scope3Total).toFixed(2));
    const totalEmissionsMarket = Number((scope1Total + scope2MarketTotal + scope3Total).toFixed(2));

    // Format reporting period for display using standard fiscal year practice
    // where fiscal year is named after the year in which it ends
    let periodLabel;
    if (month === 1) {
      periodLabel = `CY ${year}`;
    } else {
      periodLabel = `FY ${year}`;
    }

    return {
      year: year.toString(),
      reportingStartMonth: month,
      periodLabel,
      reportingPeriodStart: startDate,
      reportingPeriodEnd: endDate,
      // Scope totals
      scope1_total: scope1Total,
      scope2_total_location: scope2LocationTotal,
      scope2_total_market: scope2MarketTotal,
      scope3_total: scope3Total,
      total_emissions_location: totalEmissionsLocation,
      total_emissions_market: totalEmissionsMarket,
      // Category-specific totals
      stationary_combustion_total: Number(calculateTotal(emissionsData.scope1?.stationaryCombustion?.data).toFixed(2)),
      mobile_combustion_fb_total: Number(calculateTotal(emissionsData.scope1?.mobileCombustionLandFB?.data).toFixed(2)),
      mobile_combustion_db_total: Number(calculateTotal(emissionsData.scope1?.mobileCombustionLandDB?.data).toFixed(2)),
      fugitive_emissions_total: Number(calculateTotal(emissionsData.scope1?.fugitiveEmissions?.data).toFixed(2)),
      process_emissions_total: Number(calculateTotal(emissionsData.scope1?.processEmissions?.data).toFixed(2)),
      purchased_electricity_lb_total: Number(calculateTotal(emissionsData.scope2?.purchasedElectricityLB?.data, 'Location-based').toFixed(2)),
      purchased_electricity_mb_total: Number(calculateTotal(emissionsData.scope2?.purchasedElectricityLB?.data, 'Market-based').toFixed(2)),
      purchased_heating_total: Number(calculateTotal(emissionsData.scope2?.purchasedHeating?.data).toFixed(2)),
      purchased_goods_and_services_ss_total: Number(calculateTotal(emissionsData.scope3?.purchasedGSSS?.data).toFixed(2)),
      purchased_goods_and_services_sb_total: Number(calculateTotal(emissionsData.scope3?.purchasedGSSB?.data).toFixed(2)),
      capital_goods_total: Number(calculateTotal(emissionsData.scope3?.capitalGoodsSS?.data).toFixed(2)),
      fuel_and_energy_total: Number(calculateTotal(emissionsData.scope3?.fuelandEnergy?.data).toFixed(2)),
      upstream_transportation_total: Number(calculateTotal(emissionsData.scope3?.upstreamTransportationLandDB?.data).toFixed(2)),
      waste_generated_total: Number(calculateTotal(emissionsData.scope3?.wasteGenerated?.data).toFixed(2)),
      business_travel_total: Number(calculateTotal(emissionsData.scope3?.businessTravelLandDB?.data).toFixed(2)),
      employee_commuting_total: Number(calculateTotal(emissionsData.scope3?.employeeCommutingLandDB?.data).toFixed(2)),
      upstream_leased_assets_total: Number(calculateTotal(emissionsData.scope3?.upstreamLeasedAssets?.data).toFixed(2))
    };
  }, [reportingStartMonth]);

  const fetchBaseYearData = useCallback(async (skipCache = false) => {
    try {
      // Get current reporting period from state
      const currentReportingMonth = reportingStartMonth;
      
      // Include reporting period in the request
      const data = await makeRequest('/api/base-year', { 
        suppressNotFound: true,
        skipCache: true, // Always skip cache for reporting period changes
        params: { reportingStartMonth: currentReportingMonth }
      });
      
      if (!mountedRef.current) return;
      
      // If server returns a current base year with a reporting period, update local state and localStorage
      if (data?.current?.reportingStartMonth) {
        const serverReportingMonth = data.current.reportingStartMonth;
        
        // Only update if different from current value
        if (serverReportingMonth !== reportingStartMonth) {
          setReportingStartMonth(serverReportingMonth);
          
          // Update localStorage to match server value
          localStorage.setItem('ghg_reporting_start_month', serverReportingMonth.toString());
        }
      }

      // Process current base year if it exists
      let current = null;
      if (data?.current?.year) {
        // Use the reporting period from the server response, localStorage, or current state (in that order of preference)
        const monthToUse = data?.current?.reportingStartMonth || currentReportingMonth;
        current = calculateBaseYearTotals(emissionsData, data.current.year, monthToUse);
      }

      // Process available years - use the current reporting period for all
      const availableYears = data?.availableYears
        ?.map(yearData => {
          // Always use the current reporting month from localStorage or state
          const monthToUse = currentReportingMonth;
          const calculatedYear = calculateBaseYearTotals(emissionsData, yearData.year, monthToUse);
          
          return calculatedYear;
        })
        .filter(Boolean) || [];

      setBaseYearData({
        current,
        availableYears,
        loading: false,
        error: null
      });
    } catch (error) {
      console.error('Error fetching base year data:', error);
      if (mountedRef.current) {
        setBaseYearData(prev => ({
          ...prev,
          loading: false,
          error: error.message
        }));
      }
    }
  }, [makeRequest, emissionsData, calculateBaseYearTotals, reportingStartMonth]);

  // Define refreshData here after all dependencies are defined

  const refreshData = useCallback(async (force = false) => {
    if (!force && !shouldRefreshData()) return;
  
    try {
      debouncedSetLoading(true);
      lastFetchRef.current = Date.now();
  
      // Clear cache first to ensure fresh data
      dataCache.current = {};
      
      // First fetch emissions data
      await batchFetchEmissionsData();
      
      // Add a small delay to ensure emissions data is processed
      await new Promise(resolve => setTimeout(resolve, 300));
      
      // Then fetch base year data
      await fetchBaseYearData(true);
      
      if (!initialized) {
        setInitialized(true);
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
      if (mountedRef.current) {
        setError(error.message);
      }
    } finally {
      if (mountedRef.current) {
        debouncedSetLoading(false);
      }
    }
  }, [
    shouldRefreshData,
    initialized,
    debouncedSetLoading,
    batchFetchEmissionsData,
    fetchBaseYearData
  ]);

  // Function to update the reporting period
  const updateReportingPeriod = useCallback(async (newMonth) => {
    try {
      if (newMonth === reportingStartMonth) {
        return;
      }
      
      setLoading(true);
      
      // Update state first for immediate UI feedback
      setReportingStartMonth(newMonth);
      
      // Save to localStorage for persistence
      localStorage.setItem('ghg_reporting_start_month', newMonth.toString());
      
      // Clear cache to ensure fresh data
      clearCache();
      
      // Reset baseYearData to ensure it's fully refreshed
      setBaseYearData(initialBaseYearData);
      
      // If there's an active base year, update it with the new reporting period
      if (baseYearData?.current?.year) {
        try {
          const token = localStorage.getItem('token');
          if (token) {
            // Make a direct API call to update the base year with the new reporting period
            const response = await fetch(`${API_BASE_URL}/api/base-year`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                year: baseYearData.current.year,
                reportingStartMonth: newMonth
              })
            });
            
            if (!response.ok) {
              console.warn('Failed to update base year with new reporting period');
            }
          }
        } catch (err) {
          console.error('Error updating base year with new reporting period:', err);
        }
      }
      
      // Fetch emissions data first, then base year data
      await batchFetchEmissionsData();
      await fetchBaseYearData(true);
      
      // Double-check that localStorage has the correct value
      const savedMonth = localStorage.getItem('ghg_reporting_start_month');
      if (savedMonth !== newMonth.toString()) {
        localStorage.setItem('ghg_reporting_start_month', newMonth.toString());
      }
    } catch (error) {
      console.error('Error updating reporting period:', error);
      setError('Failed to update reporting period. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [reportingStartMonth, clearCache, batchFetchEmissionsData, fetchBaseYearData, baseYearData]);

  const handleDateRangeChange = useCallback((newRange) => {
    // Ensure we have valid dates
    if (!newRange?.start || !newRange?.end) return;

    // Format dates consistently
    const formattedRange = {
      start: new Date(newRange.start),
      end: new Date(newRange.end)
    };

    // Save to localStorage immediately
    localStorage.setItem('ghg_date_range', JSON.stringify(formattedRange));
    
    // Update context state
    setDateRange(formattedRange);
  }, []);

  // Initialize date range on mount
  useEffect(() => {
    const initialRange = getInitialDateRange();
    handleDateRangeChange(initialRange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Update date range when URL changes (page navigation)
  useEffect(() => {
    const savedRange = localStorage.getItem('ghg_date_range');
    if (savedRange) {
      try {
        const range = JSON.parse(savedRange);
        setDateRange({
          start: new Date(range.start),
          end: new Date(range.end)
        });
      } catch (e) {
        console.warn('Failed to parse saved date range:', e);
      }
    }
  }, [window.location.pathname]);

  // Effect that runs when reportingStartMonth changes
  useEffect(() => {
    if (initialized && mountedRef.current) {
      const refreshWithNewPeriod = async () => {
        try {
          setLoading(true);
          // Clear cache to ensure fresh data
          clearCache();
          
          // Fetch emissions data first, then base year data
          await batchFetchEmissionsData();
          await fetchBaseYearData(true);
        } catch (error) {
          console.error('Error refreshing after reporting period change:', error);
          setError('Failed to refresh data with new reporting period.');
        } finally {
          setLoading(false);
        }
      };
      
      refreshWithNewPeriod();
    }
  }, [reportingStartMonth, fetchBaseYearData, batchFetchEmissionsData, initialized, clearCache]);

  useEffect(() => {
    mountedRef.current = true;

    const checkAuthAndInitialize = async () => {
      const token = localStorage.getItem('token');
      if (token && !initializationAttempted.current) {
        initializationAttempted.current = true;
        try {
          setLoading(true);
          
          // Check for saved reporting period
          const savedMonth = localStorage.getItem('ghg_reporting_start_month');
          if (savedMonth) {
            const parsedMonth = parseInt(savedMonth);
            setReportingStartMonth(parsedMonth);
          } else {
            // Ensure default is saved to localStorage for future sessions
            localStorage.setItem('ghg_reporting_start_month', '1');
          }
          
          await batchFetchEmissionsData();
          // Pass true to force skip cache and ensure we get fresh data with the correct reporting period
          await fetchBaseYearData(true);
          setInitialized(true);
        } catch (error) {
          if (mountedRef.current) {
            console.error('Initialization error:', error);
            setError(error.message);
          }
        } finally {
          if (mountedRef.current) {
            setLoading(false);
          }
        }
      }
    };

    checkAuthAndInitialize();

    return () => {
      mountedRef.current = false;
      if (baseYearFetchTimeout.current) {
        clearTimeout(baseYearFetchTimeout.current);
      }
      if (authCheckInterval.current) {
        clearInterval(authCheckInterval.current);
      }
    };
  }, [batchFetchEmissionsData, fetchBaseYearData]);

  const contextValue = {
    emissionsData,
    baseYearData,
    dateRange,
    loading,
    error,
    initialized,
    earliestDate,
    latestDate,
    reportingStartMonth,
    updateReportingPeriod,
    setDateRange: handleDateRangeChange,
    refreshData,
    calculateTotals: useCallback(() => calculateTotals(emissionsData), [emissionsData]),
    getTimeSeriesData: useCallback(() => processTimeSeriesData(emissionsData, baseYearData), 
      [emissionsData, baseYearData]),
    isDataLoaded: useCallback(() => initialized && !loading, [initialized, loading]),
    getTotalsByScope: useCallback(() => ({
      scope1: calculateTotals(emissionsData).scope1Total,
      scope2: calculateTotals(emissionsData).scope2Total,
      scope3: calculateTotals(emissionsData).scope3Total
    }), [emissionsData])
  };

  return (
    <UnifiedGHGContext.Provider value={contextValue}>
      {children}
    </UnifiedGHGContext.Provider>
  );
};

export default UnifiedGHGContext;

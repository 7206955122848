// GHGBaseYearService.js
import apiClient from '../../api/axiosConfig';
import { safeParseEmissions } from './GHGUtils';

export const fetchBaseYearData = async (currentEmissionsData, force = false) => {
  try {
    const response = await apiClient.get('/api/base-year');

    // Ensure we have the expected data structure
    if (!response || (response.current === undefined && !Array.isArray(response.availableYears))) {
      throw new Error('Invalid base year data received');
    }

    // Transform the data to match the expected format
    const baseYearData = {
      current: response.current ? {
        ...response.current,
        year: response.current.year.toString(),
        // Transform values for current base year
        scope1_total: safeParseEmissions(response.current.scope1_total),
        scope2_total_location: safeParseEmissions(response.current.scope2_total_location),
        scope2_total_market: safeParseEmissions(response.current.scope2_total_market),
        scope3_total: safeParseEmissions(response.current.scope3_total),
        total_emissions_location: safeParseEmissions(response.current.total_emissions_location),
        total_emissions_market: safeParseEmissions(response.current.total_emissions_market),
        // Category-specific totals for Scope 1
        stationary_combustion_total: safeParseEmissions(response.current.stationary_combustion_total),
        mobile_combustion_fb_total: safeParseEmissions(response.current.mobile_combustion_fb_total),
        mobile_combustion_db_total: safeParseEmissions(response.current.mobile_combustion_db_total),
        fugitive_emissions_total: safeParseEmissions(response.current.fugitive_emissions_total),
        process_emissions_total: safeParseEmissions(response.current.process_emissions_total),
        // Category-specific totals for Scope 2
        purchased_electricity_lb_total: safeParseEmissions(response.current.purchased_electricity_lb_total),
        purchased_electricity_mb_total: safeParseEmissions(response.current.purchased_electricity_mb_total),
        purchased_heating_total: safeParseEmissions(response.current.purchased_heating_total),
        // Category-specific totals for Scope 3
        purchased_goods_and_services_ss_total: safeParseEmissions(response.current.purchased_goods_and_services_ss_total),
        purchased_goods_and_services_sb_total: safeParseEmissions(response.current.purchased_goods_and_services_sb_total),
        // Category-specific totals for Scope 3
        capital_goods_total: safeParseEmissions(response.current.capital_goods_total),
        // Category-specific totals for Scope 3
        fuel_and_energy_total: safeParseEmissions(response.current.fuel_and_energy_total),
        upstream_transportation_total: safeParseEmissions(response.current.upstream_transportation_total),
        waste_generated_total: safeParseEmissions(response.current.waste_generated_total),
        business_travel_total: safeParseEmissions(response.current.business_travel_total),
        employee_commuting_total: safeParseEmissions(response.current.employee_commuting_total),
        upstream_leased_assets_total: safeParseEmissions(response.current.upstream_leased_assets_total),
        change: {
          value: "0.0",
          isIncrease: false
        }
      } : null,
      availableYears: Array.isArray(response.availableYears) ? response.availableYears.map(year => {
        return {
          ...year,
          year: year.year.toString(),
          // Transform values for available years
          scope1_total: safeParseEmissions(year.scope1_total),
          scope2_total_location: safeParseEmissions(year.scope2_total_location),
          scope2_total_market: safeParseEmissions(year.scope2_total_market),
          scope3_total: safeParseEmissions(year.scope3_total),
          total_emissions_location: safeParseEmissions(year.total_emissions_location),
          total_emissions_market: safeParseEmissions(year.total_emissions_market),
          // Category-specific totals
          stationary_combustion_total: safeParseEmissions(year.stationary_combustion_total),
          mobile_combustion_fb_total: safeParseEmissions(year.mobile_combustion_fb_total),
          mobile_combustion_db_total: safeParseEmissions(year.mobile_combustion_db_total),
          fugitive_emissions_total: safeParseEmissions(year.fugitive_emissions_total),
          process_emissions_total: safeParseEmissions(year.process_emissions_total),
          purchased_electricity_lb_total: safeParseEmissions(year.purchased_electricity_lb_total),
          purchased_electricity_mb_total: safeParseEmissions(year.purchased_electricity_mb_total),
          purchased_heating_total: safeParseEmissions(year.purchased_heating_total),
          purchased_goods_and_services_ss_total: safeParseEmissions(year.purchased_goods_and_services_ss_total),
          purchased_goods_and_services_sb_total: safeParseEmissions(year.purchased_goods_and_services_sb_total),
          capital_goods_total: safeParseEmissions(year.capital_goods_total),
          fuel_and_energy_total: safeParseEmissions(year.fuel_and_energy_total),
          upstream_transportation_total: safeParseEmissions(year.upstream_transportation_total),
          waste_generated_total: safeParseEmissions(year.waste_generated_total),
          business_travel_total: safeParseEmissions(year.business_travel_total),
          employee_commuting_total: safeParseEmissions(year.employee_commuting_total),
          upstream_leased_assets_total: safeParseEmissions(year.upstream_leased_assets_total)
        };
      }) : []
    };

    // Calculate change percentage if we have current emissions data
    if (baseYearData.current && currentEmissionsData) {
      const processedData = processBaseYearData(baseYearData.current, currentEmissionsData);
      if (processedData) {
        baseYearData.current = processedData;
      }
    }

    return baseYearData;
  } catch (error) {
    console.error('Error fetching base year data:', error);
    throw error;
  }
};

export const updateBaseYear = async (yearData) => {
  if (!yearData || !yearData.year) {
    throw new Error('Invalid year data provided');
  }

  try {
    const response = await apiClient.put('/api/base-year', { year: yearData.year });

    if (!response) {
      throw new Error('Invalid response from server');
    }

    // Transform the response to match expected format
    const transformedResponse = {
      ...response,
      year: response.year.toString(),
      // Scope totals
      scope1_total: safeParseEmissions(response.scope1_total),
      scope2_total_location: safeParseEmissions(response.scope2_total_location),
      scope2_total_market: safeParseEmissions(response.scope2_total_market),
      scope3_total: safeParseEmissions(response.scope3_total),
      total_emissions_location: safeParseEmissions(response.total_emissions_location),
      total_emissions_market: safeParseEmissions(response.total_emissions_market),
      // Category-specific totals for Scope 1
      stationary_combustion_total: safeParseEmissions(response.stationary_combustion_total),
      mobile_combustion_fb_total: safeParseEmissions(response.mobile_combustion_fb_total),
      mobile_combustion_db_total: safeParseEmissions(response.mobile_combustion_db_total),
      fugitive_emissions_total: safeParseEmissions(response.fugitive_emissions_total),
      process_emissions_total: safeParseEmissions(response.process_emissions_total),
      // Category-specific totals for Scope 2
      purchased_electricity_lb_total: safeParseEmissions(response.purchased_electricity_lb_total),
      purchased_electricity_mb_total: safeParseEmissions(response.purchased_electricity_mb_total),
      purchased_heating_total: safeParseEmissions(response.purchased_heating_total),
      // Category-specific totals for Scope 3
      purchased_goods_and_services_ss_total: safeParseEmissions(response.purchased_goods_and_services_ss_total),
      purchased_goods_and_services_sb_total: safeParseEmissions(response.purchased_goods_and_services_sb_total),
      capital_goods_total: safeParseEmissions(response.capital_goods_total),
      fuel_and_energy_total: safeParseEmissions(response.fuel_and_energy_total),
      upstream_transportation_total: safeParseEmissions(response.upstream_transportation_total),
      waste_generated_total: safeParseEmissions(response.waste_generated_total),
      business_travel_total: safeParseEmissions(response.business_travel_total),
      employee_commuting_total: safeParseEmissions(response.employee_commuting_total),
      upstream_leased_assets_total: safeParseEmissions(response.upstream_leased_assets_total),
      change: {
        value: "0.0",
        isIncrease: false
      }
    };

    return transformedResponse;
  } catch (error) {
    console.error('Error updating base year:', error);
    throw error;
  }
};

export const processBaseYearData = (baseYearData, currentEmissionsData) => {
  if (!baseYearData || !currentEmissionsData) return null;

  const baseEmissions = parseFloat(baseYearData.total_emissions_market) || 0;
  const currentEmissions = parseFloat(currentEmissionsData.total_emissions_market) || 0;

  if (baseEmissions === 0) return baseYearData;

  const change = ((currentEmissions - baseEmissions) / baseEmissions) * 100;
  return {
    ...baseYearData,
    change: {
      value: Math.abs(change).toFixed(1),
      isIncrease: change > 0
    }
  };
};

export const validateBaseYearData = (data) => {
  if (!data) return false;
  
  // Helper function to validate emissions value
  const isValidEmissions = (value) => {
    return typeof value === 'string' || typeof value === 'number';
  };

  // Required base fields
  const hasRequiredFields = (
    typeof data.year === 'string' &&
    isValidEmissions(data.scope1_total) &&
    isValidEmissions(data.scope2_total_location) &&
    isValidEmissions(data.scope2_total_market) &&
    isValidEmissions(data.scope3_total) &&
    isValidEmissions(data.total_emissions_location) &&
    isValidEmissions(data.total_emissions_market)
  );

  if (!hasRequiredFields) return false;

  // Category-specific fields - these are optional but should be valid if present
  const categoryFields = [
    // Scope 1 categories
    'stationary_combustion_total',
    'mobile_combustion_fb_total',
    'mobile_combustion_db_total',
    'fugitive_emissions_total',
    'process_emissions_total',
    // Scope 2 categories
    'purchased_electricity_lb_total',
    'purchased_electricity_mb_total',
    'purchased_heating_total',
    // Scope 3 categories
    'purchased_goods_and_services_ss_total',
    'purchased_goods_and_services_sb_total',
    'capital_goods_total',
    'fuel_and_energy_total',
    'upstream_transportation_total',
    'waste_generated_total',
    'business_travel_total',
    'employee_commuting_total',
    'upstream_leased_assets_total'
  ];

  // Check that any present category fields are valid
  const hasValidCategoryFields = categoryFields.every(field => {
    return !data[field] || isValidEmissions(data[field]);
  });

  return hasValidCategoryFields;
};

export const calculateYearEmissions = (yearData) => {
  if (!yearData) return null;
  
  const scope1 = parseFloat(yearData.scope1_total) || 0;
  const scope2Location = parseFloat(yearData.scope2_total_location) || 0;
  const scope2Market = parseFloat(yearData.scope2_total_market) || 0;
  const scope3 = parseFloat(yearData.scope3_total) || 0;

  return {
    location: (scope1 + scope2Location + scope3).toFixed(1),
    market: (scope1 + scope2Market + scope3).toFixed(1)
  };
};

export const getComparison = (baseYearData, currentEmissionsData) => {
  if (!baseYearData || !currentEmissionsData) return null;

  const baseEmissions = calculateYearEmissions(baseYearData);
  const currentEmissions = calculateYearEmissions(currentEmissionsData);

  if (!baseEmissions || !currentEmissions) return null;

  const locationChange = ((parseFloat(currentEmissions.location) - parseFloat(baseEmissions.location)) / parseFloat(baseEmissions.location)) * 100;
  const marketChange = ((parseFloat(currentEmissions.market) - parseFloat(baseEmissions.market)) / parseFloat(baseEmissions.market)) * 100;

  return {
    location: {
      value: Math.abs(locationChange).toFixed(1),
      isIncrease: locationChange > 0
    },
    market: {
      value: Math.abs(marketChange).toFixed(1),
      isIncrease: marketChange > 0
    }
  };
};
